<cc-dropdown-action
  (click)="toggle()"
  [disabled]="disabled"
  [selectable]="true"
  [selected]="selected"
>
  <ng-container slot="title">
    {{ value }}
  </ng-container>
</cc-dropdown-action>
